import React from "react";
import styled from "styled-components";
import { fonts, ourStoryStyles, resourcesStyles } from "../../styles.jsx";
import { Card } from "../Common/ResusableComponents.jsx";
import example from "../../images/ourStory/exampleMember.svg";
import nilai from "../../images/ourStory/Team/nilai.jpg";
import eric from "../../images/ourStory/Team/eric.jpg";
import esther from "../../images/ourStory/Team/esther.jpg";
import akshay from "../../images/ourStory/Team/akshay.jpg";
import malia from "../../images/ourStory/Team/malia.jpg";
import james from "../../images/ourStory/Team/james.jpg";
import guada from "../../images/ourStory/Team/guada.jpg";

const { black } = resourcesStyles;
const { secondary, white } = ourStoryStyles;
const { mont } = fonts;

const OurTeamWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: linear-gradient(
    to top,
    ${white} 0%,
    ${white} 30%,
    ${secondary} 30%,
    ${secondary} 100%
  );
  border-radius: 20px;
  min-height: 600px;
  margin-bottom: 32px;
`;

const OurTeamTitle = styled.div`
  margin-top: 32px;
  margin-left: 32px;
  background-color: ${white};
  color: ${secondary};
  font-size: 40px;
  font-weight: 600;
  font-family: ${mont};
  width: 250px;
  text-align: center;
  border-radius: 5px;
  @media (max-width: 600px) {
    align-self: center;
    margin-left: 0;
  }
`;

const TitleNamePictureWrapper = styled.div`
  padding-top: 64px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
  width: 100%;
  justify-content: space-around;
  justify-items: center;
  @media (max-width: 1200px) {
    grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
  }
`;

const TeamCard = styled(Card)`
  height: 400px;
  width: 275px;
  background-color: ${white};
  display: flex;
  flex-direction: column;
  font-family: ${mont};
  overflow: hidden;
`;

const Role = styled.div`
  color: ${black};
  padding-left: 16px;
  padding-top: 16px;
  font-size: 20px;
`;

const Name = styled.div`
  color: ${secondary};
  padding-left: 16px;
  padding-top: 16px;
  font-size: 40px;
`;

const MemberImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const TEAM = [
  {
    last_name: "Sharma",
    first_name: "Akshay",
    role: "Executive Director",
    src: akshay,
    linkedIn: "https://www.linkedin.com/company/whychildcancer/",
  },
  {
    last_name: "Jiang",
    first_name: "Eric",
    role: "President",
    src: eric,
    linkedIn: "https://www.linkedin.com/in/ericfoxjiang/",
  },
  {
    last_name: "Jiang",
    first_name: "Malia",
    role: "Director of Growth",
    src: malia,
    linkedIn: "https://www.linkedin.com/in/malia-jiang/",
  },
  {
    last_name: "Patel",
    first_name: "Nilai",
    role: "Technology Lead",
    src: nilai,
    linkedIn: "https://www.linkedin.com/in/nilaipatel/",
  },
  {
    last_name: "Herrera",
    first_name: "Guada",
    role: "Director of Marketing",
    src: guada,
    linkedIn: "https://www.linkedin.com/in/maria-herrera-605243158/",
  },
  {
    last_name: "Van",
    first_name: "James",
    role: "Software Engineer",
    src: james,
    linkedIn: "https://www.linkedin.com/in/jmvan/",
  },
  {
    last_name: "Esther",
    first_name: "Kuang",
    role: "Software Engineer",
    src: esther,
    linkedIn: "https://www.linkedin.com/in/estherkuang/",
  },
  {
    last_name: "You?",
    first_name: "And",
    role: "To be determined",
    src: example,
  },
];

const Team = () => (
  <OurTeamWrapper>
    <OurTeamTitle>Our Team</OurTeamTitle>
    <TitleNamePictureWrapper>
      {TEAM.map(({ role, first_name, last_name, src, linkedIn }) => (
        <a
          href={linkedIn}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none" }}
          key={`${first_name} ${last_name}`}
        >
          <TeamCard>
            <Role>{role}</Role>
            <Name>
              <div>{first_name}</div>
              <div>{last_name}</div>
            </Name>
            <MemberImage alt={`${first_name} ${last_name}`} src={src} />
          </TeamCard>
        </a>
      ))}
    </TitleNamePictureWrapper>
  </OurTeamWrapper>
);

export default Team;
