import React, { useState } from "react";
import styled from "styled-components";
import { fonts, homeStyles } from "../../styles";
import { Link } from "react-router-dom";

const { mont } = fonts;

const MenuContainer = styled.div`
  position: relative;
  margin: 32px 32px 0px 0px;
  @media (min-width: 801px) {
    display: none;
  }
`;

const HamburgerIcon = styled.div`
  width: 30px;
  cursor: pointer;
`;

const Bar = styled.div`
  width: 100%;
  height: 4px;
  background-color: ${homeStyles.white};
  margin: 5px 0;
  transition: 0.4s;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 35px;
  right: 0;
  background-color: ${homeStyles.white};
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 4;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
`;

const MenuItem = styled(Link)`
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-family: ${mont};
  background-color: transparent;
  border-radius: 5px;
  &:hover {
    background-color: #ddd;
    pointer-events: auto;
  }
`;

const Hamburger = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <MenuContainer>
      <HamburgerIcon onClick={handleToggle}>
        <Bar />
        <Bar />
        <Bar />
      </HamburgerIcon>
      {isOpen && (
        <DropdownMenu>
          <MenuItem to="/">Home</MenuItem>
          <MenuItem to="/our-story">Our Story</MenuItem>
          <MenuItem to="/donate">Support Us</MenuItem>
        </DropdownMenu>
      )}
    </MenuContainer>
  );
};

export default Hamburger;
