import styled from "styled-components";
import React from "react";
import {
  homeStyles,
  fonts,
  ourStoryStyles,
  resourcesStyles,
  getInvolvedStyles,
  donateStyles,
} from "../../styles.jsx";
import fb from "../../images/facebook.png";
import insta from "../../images/instagram.png";
import x from "../../images/twitter.png";

const { mont } = fonts;

const TopLeftFooter = styled.div`
  padding: 32px 16px 16px 16px;
  color: ${donateStyles.white};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const TopRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0 64px 0 32px;
  font-family: ${mont};

  @media (max-width: 900px) {
    padding: 16px 0px;
    justify-content: center;
  }
`;

const getFooterColor = ({ page }) => {
  switch (page) {
    case "home":
      return homeStyles.primary;
    case "our_story":
      return ourStoryStyles.primary;
    case "resources":
      return resourcesStyles.green;
    case "get_involved":
      return getInvolvedStyles.yellow;
    case "donate":
      return homeStyles.primary;
    default:
      return homeStyles.primary;
  }
};

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 180px;
  font-family: ${mont};
  background-color: ${getFooterColor};
  color: ${donateStyles.white};

  @media (max-width: 768px) {
    height: 100%;
  }
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

const Socials = [
  {
    href: "https://www.facebook.com/whychildcancer/",
    alt: "facebook logo",
    src: fb,
  },
  {
    href: "https://www.instagram.com/whychildcancer/",
    alt: "intsagram logo",
    src: insta,
  },
  {
    href: "https://www.twitter.com/whychildcancer/",
    alt: "twitter logo",
    src: x,
  },
];

const SocialMediaWrapper = styled.div`
  width: 160px;
  display: flex;
  justify-content: space-between;
  padding-right: 8px;
`;

const Social = () => (
  <SocialMediaWrapper>
    {Socials.map(({ href, alt, src }) => (
      <a href={href} key={alt} target="_blank" rel="noopener noreferrer">
        <img src={src} alt={alt} width="32px" height="32px" />
      </a>
    ))}
  </SocialMediaWrapper>
);

const Footer = ({ page }) => (
  <FooterWrapper page={page}>
    <TopLeftFooter>
      <div>
        EIN: 81-1385343
        {"\n"}
      </div>
      <div>
        Union City, CA
        {"\n"}
      </div>
    </TopLeftFooter>
    <TopRight>
      <Social />
    </TopRight>
  </FooterWrapper>
);

export default Footer;
