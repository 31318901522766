import styled from "styled-components";
import {
  homeStyles,
  fonts,
  resourcesStyles,
  getInvolvedStyles,
} from "../../styles.jsx";

const { mont, fred, karla } = fonts;

export const Card = styled.div`
  border-radius: 16px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.6s;
  &:hover {
    box-shadow: 0 32px 64px 0 rgba(0, 0, 0, 0.2);
  }
`;

export const Button = styled.button`
  outline: 0;
  border: 0;
  border-radius: 20px;
  ${({ name }) => {
    switch (name) {
      case "donate":
        return `
          color: ${homeStyles.white};
          background-color:  ${getInvolvedStyles.salmon};
          font-weight: 900;
          font-family: ${fred};
        `;
      case "learn_more":
        return `
          font-size: 24px;
          font-family: ${fred};
          color: ${homeStyles.primary};
          background-color:  ${homeStyles.lightBlue};
        `;
      case "give_here":
        return `
          font-size: 24px;
          font-family: ${karla};
          color: ${homeStyles.white};
          background-color: ${homeStyles.superPowersRed};
          font-weight: 700;
        `;
      case "volunteer_here":
        return `
          font-size: 24px;
          font-family: ${karla};
          color: ${homeStyles.white};
          background-color: ${homeStyles.yellow};
          font-weight: 700;
        `;
      case "sponsor":
        return `
          font-size: 24px;
          font-family: ${karla};
          color: ${homeStyles.white};
          background-color: ${homeStyles.darkGreen};
          font-weight: 700;
        `;
      case "buy_it_here":
        return `
          font-family: ${mont};
          color: ${homeStyles.white};
          background-color: ${homeStyles.darkBlue};
          align-self: start;
        `;
      case "buy_today":
        return `
          font-family: ${mont};
          color: ${resourcesStyles.white};
          background-color: ${resourcesStyles.cyan};
          align-self: center;
          padding-left: 64px;
          padding-right: 64px;
        `;
      default:
        return "color: 'purple'; background-color:  purple;";
    }
  }}};
  ${({ size }) => {
    switch (size) {
      case "lg":
        return "padding: 16px 16px 16px 16px; font-size: 1.2em;";
      case "md":
        return "padding: 16px 8px 16px 8px; font-size: 1em;";
      case "sm":
        return "padding: 2px 1px 2px 1px; font-size: 0.8em;";
      default:
        return "padding: 0 0 0 0; font-size: 0.8em;";
    }
  }}
  &:active {
    vertical-align: top;
    padding-top: 8px;
  }
`;
