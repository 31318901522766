import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { homeStyles } from "../../styles";

const GROUP_CLOUD_TIME = 100;

const moveSun = keyframes`
0% { 
  left: -50%;
  top: 50%;
}
10% { 
  left: -30%; 
  top: 35%;
}
20% { 
  left: -10%; 
  top: 20%;
}
30% { 
  left: 10%; 
  top: 10%;
}
40% { 
  left: 30%; 
  top: 4%;
}
45% {
  left: 40%;
  top: 1%;
}
47.5% {
  left: 45%;
  top: 0.3%;
}
50% { 
  left: 50%; 
  top: 0%;
}
52.5% {
  left: 55%;
  top: 0.3%;
}
55% {
  left: 60%;
  top: 1%;
}
60% { 
  left: 70%; 
  top: 4%;
}
70% { 
  left: 90%; 
  top: 10%;
}
80% { 
  left: 110%; 
  top: 20%;
}
90% { 
  left: 130%; 
  top: 35%;
}
100% { 
  left: 150%; 
  top: 50%;
}
`;

// Create a styled component for the sun
export const SunWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50px;
  height: 50px;
  background-color: ${homeStyles.sun};
  border-radius: 50%;
  animation: ${moveSun} 60s linear infinite;
  z-index: 0;
`;

const startCloudLoop = (start = 0) => keyframes`
  0% { left: ${start}%; }
  100% { left: 150%; }
`;

const moveCloudLoop = () => keyframes`
  0% { left: -150%; }
  100% { left: 150%; }
`;

const CloudAWrapper = styled.div`
  position: absolute;
  top: 40px;
  z-index: 1;
  overflow: hidden;
  background-size: contain;
  animation:
    ${startCloudLoop()} 13s linear forwards,
    ${moveCloudLoop()} 26s linear infinite 13s;
`;

const CloudBWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: 300px;
  overflow: hidden;
  background-size: contain;
  animation: ${moveCloudLoop()} 32s linear infinite;
`;

const CloudsWrapper = styled.div`
  position: relative;
  width: 100%;
  z-index: 3;
`;

const Cloud = styled.div`
  margin-top: 70px;
  width: 350px;
  height: 120px;
  background: ${homeStyles.white};
  border-radius: 100px;
  &:after,
  &:before {
    content: "";
    position: relative;
    display: inline-block;
    background: inherit;
    border-radius: inherit;
  }
`;

const CloudAStyles = styled(Cloud)`
  &:after {
    width: 100px;
    height: 100px;
    top: -120px;
    left: -100px;
  }
  &:before {
    width: 180px;
    height: 180px;
    top: -70px;
    left: 130px;
  }
`;

const CloudBStyles = styled(Cloud)`
  &:after {
    width: 80px;
    height: 80px;
    top: -150px;
    left: -90px;
  }
  &:before {
    width: 200px;
    height: 200px;
    top: -60px;
    left: 140px;
  }
`;

const CloudInGroup = styled(CloudAStyles)`
  position: absolute;
  animation:
    ${({ leftPercentage }) => startCloudLoop(leftPercentage)}
      ${({ delay }) => delay}s linear forwards,
    ${moveCloudLoop()} ${({ time = GROUP_CLOUD_TIME }) => time}s linear infinite
      ${({ delay }) => delay}s;
`;

export const CloudA = () => (
  <CloudAWrapper>
    <CloudAStyles style={{ background: "#5AB3F0" }} />
  </CloudAWrapper>
);

export const CloudB = () => (
  <CloudBWrapper>
    <CloudBStyles style={{ background: "#5AB3F0" }} />
  </CloudBWrapper>
);

export const Clouds = () => {
  const [cloudCount, setCloudCount] = useState(0);

  useEffect(() => {
    const updateCloudCount = () => {
      const screenWidth = window.innerWidth;
      const count = Math.floor(screenWidth / 100);
      setCloudCount(count >= 5 ? count : 5);
    };

    updateCloudCount();
    window.addEventListener("resize", updateCloudCount);

    return () => {
      window.removeEventListener("resize", updateCloudCount);
    };
  }, []);

  return (
    <CloudsWrapper>
      {Array.from({ length: cloudCount }).map((_, index) => {
        const leftPercentage = (index / (cloudCount - 1)) * 300 - 150;
        const delay =
          GROUP_CLOUD_TIME - (index / (cloudCount - 1)) * GROUP_CLOUD_TIME;
        return (
          <CloudInGroup
            key={`cloud-border-${index}`}
            leftPercentage={leftPercentage}
            delay={delay}
          />
        );
      })}
      {Array.from({ length: 3 }).map((_, index) => {
        const leftPercentage = (index / (cloudCount - 1)) * 300 - 150;
        const random = Math.random() * 20 + 10;
        const delay = random - (index / (cloudCount - 1)) * random;
        return (
          <CloudInGroup
            key={`cloud-border-${index}`}
            leftPercentage={leftPercentage}
            delay={delay}
            time={random}
          />
        );
      })}
    </CloudsWrapper>
  );
};
