import React from "react";
import styled, { keyframes } from "styled-components";
import { skyColorChange } from "../../styles.jsx";
import { DonateButton } from "../Common/Header.jsx";
import hero from "../../images/home/homePageBeAHero.svg";
import circle from "../../images/home/homePageCircle.png";
import {
  CloudA,
  CloudB,
  Clouds,
  SunWrapper as Sun,
} from "../Common/Clouds.jsx";

const HomeWrapper = styled.div`
  animation: ${skyColorChange} 60s linear infinite;
  display: flex;
  padding: 170px 12%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  @media (max-width: 768px) {
    padding-top: 32px;
    padding-bottom: 12%;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;

  @media (max-width: 768px) {
    padding: 25px 0;
  }
`;

const LeftSection = styled(Section)`
  padding-right: 64px;
  align-items: start;
  justify-content: start;
  @media (max-width: 768px) {
    padding-right: 0;
  }
`;

const RightSection = styled(Section)`
  padding-left: 64px;
  @media (max-width: 768px) {
    padding-left: 0;
  }
`;

const Phrase = styled.img`
  padding-bottom: 16px;
  width: 400px;
  height: 200px;
  @media (max-width: 1250px) {
    padding-bottom: 0px;
    width: 300px;
    height: 150px;
  }
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const CircleImage = styled.img`
  width: 300px;
  height: 300px;
  border-radius: 50%;
  transition: transform 1s ease-in-out;
  &:hover {
    animation: ${spin} 8s linear infinite;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Opener = () => (
  <HomeWrapper>
    <CloudA />
    <CloudB />
    <Sun />
    <Wrapper>
      <LeftSection>
        <Phrase alt="Hero" src={hero} />
        <DonateButton size={"big"} />
      </LeftSection>
      <RightSection>
        <CircleImage alt="Children" src={circle} />
      </RightSection>
    </Wrapper>
    <Clouds />
  </HomeWrapper>
);

export default Opener;
