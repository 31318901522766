import { useEffect, useState } from "react";
import Webfont from "webfontloader";

const FontLoader = ({ children }) => {
  const [fontsLoaded, setFontsLoaded] = useState(false);

  useEffect(() => {
    Webfont.load({
      google: {
        families: [
          "Poppins:wght@100",
          "Fredoka One",
          "Karla:wght@400;700",
          "Montserrat:ital,wght@0,400;0,500;0,700;0,900;1,300",
        ],
      },
      active: () => setFontsLoaded(true),
      inactive: () => setFontsLoaded(true),
    });
  }, []);

  return fontsLoaded ? children : null;
};

export default FontLoader;
