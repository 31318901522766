import React from "react";
import styled from "styled-components";
import { homeStyles, fonts } from "../../styles.jsx";
import fundraiser from "../../images/home/homePageFundraiser.svg";
import crayonKid from "../../images/home/homePageKidCrayon.svg";

const { white, darkBlue } = homeStyles;
const { mont } = fonts;

const MissionWrapper = styled.div`
  background-color: ${white};
  display: flex;
  padding-left: 12%;
  padding-right: 12%;
  padding-bottom: 64px;
  align-items: center;
  justify-content: center;
  @media (max-width: 980px) {
    flex-direction: column;
  }
`;

const LeftSection = styled.div`
  display: flex;
`;

const LeftImage = styled.img`
  display: flex;
  width: 256px;
  height: 256px;
`;

const MiddleSection = styled.div`
  display: flex;
`;

const MiddleImage = styled.img`
  display: flex;
  width: 320px;
  height: 320px;
`;

const RightSection = styled.div`
  display: flex;
  width: 408px;
  height: 256px;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const RightTitle = styled.div`
  display: flex;
  align-self: flex-start;
  font-size: 32px;
  font-family: ${mont};
  color: ${darkBlue};
  font-weight: 900;
  padding-bottom: 16px;
`;

const RightText = styled.div`
  display: flex;
  align-self: flex-start;
  font-size: 20px;
  font-family: ${mont};
  color: ${darkBlue};
  line-height: 1.6;
  font-weight: 400;
`;

const KidWithABeard = () => (
  <MissionWrapper>
    <LeftSection>
      <LeftImage alt="fundraiser" src={fundraiser} />
    </LeftSection>
    <MiddleSection>
      <MiddleImage alt="Kid with crayon" src={crayonKid} />
    </MiddleSection>
    <RightSection>
      <RightTitle>The Kid With a Beard</RightTitle>
      <RightText>
        With each donation, go on an adventure with Kid in this interactive
        coloring storybook!
      </RightText>
    </RightSection>
  </MissionWrapper>
);

export default KidWithABeard;
