/* eslint-disable react/no-children-prop */
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./components/Home.jsx";
import Donate from "./components/Donate.jsx";
import OurStory from "./components/OurStory.jsx";
import { GlobalStyles } from "./styles.jsx";

const Routes = [
  { path: "/donate", children: <Donate /> },
  { path: "/our-story", children: <OurStory /> },
  { path: "/", children: <Home /> },
];

const App = () => (
  <Router>
    <GlobalStyles />
    <Switch>
      {Routes.map(({ path, children }) => (
        <Route path={path} children={children} key={path} />
      ))}
    </Switch>
  </Router>
);

export default App;
