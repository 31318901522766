import React from "react";
import styled from "styled-components";
import { homeStyles, fonts } from "../../styles.jsx";
import rabbit from "../../images/home/homePageRabbit.svg";

const { white, darkGreen, lightGreen, lightBlack } = homeStyles;
const { mont } = fonts;

const ImpactNumbersWrapper = styled.div`
  background-color: ${white};
  display: flex;
  padding-left: 12%;
  padding-right: 12%;
  padding-bottom: 64px;
  align-items: center;
  justify-content: center;

  @media (max-width: 1250px) {
    flex-direction: column;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  }
`;

const LeftSection = styled.div`
  display: flex;
  padding-right: 128px;
  @media (max-width: 768px) {
    padding-right: 0px;
  }
`;

const RightSection = styled.div`
  display: flex;
  width: 600px;
  height: 256px;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
  }
`;

const LeftImage = styled.img`
  display: flex;
  width: 320px;
  height: 320px;
`;

const RightTitle = styled.div`
  display: flex;
  align-self: center;
  font-size: 32px;
  font-family: ${mont};
  color: ${darkGreen};
  font-weight: 900;
  padding-bottom: 32px;
  padding-left: 16px;
`;

const RightNumbersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

const RightStats = styled.div`
  display: flex;
  flex-direction: column;
`;

const RightNumber = styled.div`
  display: flex;
  justify-content: center;
  font-family: ${mont};
  color: ${lightGreen};
  font-weight: 700;
  font-size: 32px;
`;

const RightText = styled.div`
  display: flex;
  justify-content: center;
  font-family: ${mont};
  color: ${lightBlack};
  font-weight: 500;
  font-size: 20px;
`;

const ImpactNumbers = () => (
  <ImpactNumbersWrapper>
    <LeftSection>
      <LeftImage alt="rabbit" src={rabbit} />
    </LeftSection>
    <RightSection>
      <RightTitle>Our Impact in Numbers:</RightTitle>
      <RightNumbersWrapper>
        <RightStats>
          <RightNumber>$10K+</RightNumber>
          <RightText>Donations</RightText>
        </RightStats>
        <RightStats>
          <RightNumber>10+</RightNumber>
          <RightText>Families helped</RightText>
        </RightStats>
        <RightStats>
          <RightNumber>100+</RightNumber>
          <RightText>Smiles shared</RightText>
        </RightStats>
      </RightNumbersWrapper>
    </RightSection>
  </ImpactNumbersWrapper>
);

export default ImpactNumbers;
