import React from "react";
import styled from "styled-components";
import { homeStyles, fonts } from "../../styles.jsx";
import { Card } from "../Common/ResusableComponents.jsx";
import heart from "../../images/home/homePageHeart.svg";
import star from "../../images/home/homePageStar.svg";
import megaphone from "../../images/home/homePageMegaphone.svg";
import { Link } from "react-router-dom/cjs/react-router-dom.min.js";
const { white, green, yellow, gray, superPowersBlue, superPowersRed } =
  homeStyles;
const { mont } = fonts;

const BottomWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
  }
`;

const CardSpacer = styled.div`
  display: flex;
  padding: 16px 16px;
`;

const SuperWrapper = styled.div`
  background-color: ${white};
  display: flex;
  padding-left: 12%;
  padding-right: 12%;
  padding-top: 8px;
  padding-bottom: 8px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 768px) {
    padding: 16px;
  }
`;

const ChooseTitle = styled.h1`
  display: flex;
  font-size: 32px;
  font-family: ${mont};
  color: ${superPowersBlue};
  font-weight: 900;
  padding-bottom: 48px;
  text-align: center;
`;

const PictureTitleTextButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 226px;
  padding: 16px;
`;

const PictureWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 16px;
`;

const Picture = styled.img`
  display: flex;
  width: 96px;
  height: 96px;
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  font-size: 24px;
  font-family: ${mont};
  color: ${({ name }) => {
    if (name === "donate") {
      return superPowersRed;
    }
    if (name === "team_up") {
      return yellow;
    }
    if (name === "sponsor") {
      return green;
    }
  }};
  font-weight: 700;
  padding-bottom: 16px;
`;

const Text = styled.div`
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-family: ${mont};
  color: ${gray};
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 16px;
`;

const SuperPowers = () => (
  <SuperWrapper>
    <ChooseTitle>Choose Your Superpower</ChooseTitle>
    <BottomWrapper>
      <Card>
        <Link to="/donate" style={{ textDecoration: "none" }}>
          <PictureTitleTextButtonWrapper>
            <PictureWrapper>
              <Picture alt="heart" src={heart} />
            </PictureWrapper>
            <Title name="donate">Donate</Title>
            <Text>
              Take a moment and spare some change. Every penny counts in helping
              a child and their family through a challenging life experience.
            </Text>
          </PictureTitleTextButtonWrapper>
        </Link>
      </Card>
      <CardSpacer />
      <Card>
        <Link to="/our-story" style={{ textDecoration: "none" }}>
          <PictureTitleTextButtonWrapper>
            <PictureWrapper>
              <Picture alt="star" src={star} />
            </PictureWrapper>
            <Title name="team_up">Team Up!</Title>
            <Text>
              Take charge and set up a fundraiser for Why or club at your
              school.
            </Text>
          </PictureTitleTextButtonWrapper>
        </Link>
      </Card>
      <CardSpacer />
      <Card>
        <Link to="/donate" style={{ textDecoration: "none" }}>
          <PictureTitleTextButtonWrapper>
            <PictureWrapper>
              <Picture alt="megaphone" src={megaphone} />
            </PictureWrapper>
            <Title name="sponsor">Sponsor</Title>
            <Text>
              Don’t just donate, have fun with it. Check out our latest product
              so that you have a gift to take home with.
            </Text>
          </PictureTitleTextButtonWrapper>
        </Link>
      </Card>
    </BottomWrapper>
  </SuperWrapper>
);

export default SuperPowers;
