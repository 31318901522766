import { createGlobalStyle, keyframes } from "styled-components";

export const homeStyles = {
  primary: "#184263", // blue
  secondary: "#B0E1F3",
  white: "#FDFDFD",
  green: "#68C773",
  pink: "#F4ADE4",
  mustard: "#F7D959",
  gray: "#717171",
  black: "#424242",
  boldBlack: "#212121",
  lightBlue: "#89CBFD",
  superPowersBlue: "#4683A7", // SuperPowers.jsx
  superPowersRed: "#BA5868", // SuperPowers.jsx
  yellow: "#EDB664", // AmazonSmile.jsx and SuperPowers.jsx
  golden: "#BB670D", // AmazonSmile.jsx
  darkGreen: "#347055", // ImpactNumbers.jsx and SuperPowers.jsx
  lightGreen: "#3CAB79", // Impact Numbers.jsx
  lightBlack: "#184263", // ImpactNumbers.jsx
  darkBlue: "#2971A8", // KidWithABeard.jsx
  sun: "#FFFF00",
};

export const getInvolvedStyles = {
  yellow: "#F7D959",
  salmon: "#FD757E",
};
export const ourStoryStyles = {
  primary: "#5AB3F0", // blue as well
  secondary: "#5AB3F0", // blue
  darkBlue: "#2971A8", // KidWithABeard.jsx
  white: "#FDFDFD",
};
export const resourcesStyles = {
  green: "#68C773",
  lightGreen: "#68C77348",
  darkGreen: "#28602F",
  white: "#FDFDFD",
  black: "#424242",
  cyan: "#50BDE4",
  blue: "#5AB3F0",
};
export const donateStyles = {
  blue: "#5AB3F0",
  salmon: "#FD757E",
  white: "#FDFDFD",
  black: "#424242",
  sky: "#F4FAFF", // page background
};

export const contactUsStyles = {
  secondary: "#5AB3F0", // blue
  white: "#FDFDFD",
  superPowersRed: "#BA5868",
  salmon: "#FD757E",
};

export const fonts = {
  pop: "'Poppins', sans-serif",
  fred: "'Fredoka One', cursive",
  mont: "'Montserrat', sans-serif",
  karla: "'Karla', sans-serif",
};

export const GlobalStyles = createGlobalStyle`
  h2 {
    font-size: 24px;
  }
  input {
    font-family: ${fonts.mont};
    font-size: 16px;
  }
  textarea {
    font-family: ${fonts.mont};
    font-size: 16px;
  }
`;

export const skyColorChange = keyframes`
  0% {
    background-color: #184263;
  }
  50% {
    background-color: #5AB3F0;
  }
  100% {
    background-color: #184263;
  }
`;
