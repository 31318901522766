import React from "react";
import styled from "styled-components";
import Header from "./Common/Header.jsx";
import Footer from "./Common/Footer.jsx";
import Paypal from "./Donate/Paypal.jsx";
import ResourcesBook from "./Donate/Resources.jsx";
import { fonts, resourcesStyles } from "../styles.jsx";
import { ourStoryStyles } from "../styles.jsx";

const { white } = resourcesStyles;
const { mont } = fonts;
const { secondary } = ourStoryStyles;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: ${secondary};
  margin-top: 100px;
`;

const BoxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  font-weight: 700;
  font-family: ${mont};
  background-color: ${white};
  color: ${secondary};
  height: 64px;
  width: 200px;
  margin-top: 50px;
  margin-bottom: 10px;
  border-radius: 4px;
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 27px;
  font-family: ${mont};
  color: ${white};
  padding-left: 10%;
  padding-right: 10%;
  margin-bottom: 50px;
  text-align: center;
`;

const Donate = () => (
  <>
    <Header page="donate" />
    <TitleWrapper>
      <BoxWrapper>Donate</BoxWrapper>
      <TextWrapper>share a smile through a donation</TextWrapper>
    </TitleWrapper>
    <Paypal />
    <ResourcesBook />
    <Footer page="donate" />
  </>
);

export default Donate;
