import React, { Suspense, lazy } from "react";
import Footer from "./Common/Footer.jsx";
import Header from "./Common/Header.jsx";
import ContactUs from "./Common/ContactUs.jsx";
import OurTeam from "./OurStory/OurTeam.jsx";
import Letter from "./OurStory/Letter.jsx";
import giraffe from "../images/ourStory/ourStoryGiraffe.svg";
import styled, { keyframes } from "styled-components";
import { fonts, ourStoryStyles, homeStyles } from "../styles.jsx";
const Collage = lazy(() => import("./OurStory/Collage.jsx"));

const { secondary, white } = ourStoryStyles;
const { gray } = homeStyles;
const { mont } = fonts;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${secondary};
  margin-top: 100px;
`;

const BoxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  font-weight: 700;
  font-family: ${mont};
  background-color: ${white};
  color: ${secondary};
  height: 64px;
  width: 240px;
  margin-top: 50px;
  margin-bottom: 10px;
  border-radius: 4px;
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 27px;
  font-family: ${mont};
  color: ${white};
  padding-left: 10%;
  padding-right: 10%;
  margin-bottom: 50px;
  text-align: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 50vw;
  max-height: 532px;
  background: linear-gradient(
    to top,
    ${white} 0%,
    ${white} 10%,
    ${secondary} 10%,
    ${secondary} 100%
  );
`;

const pulseAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`;

const Fallback = styled.div`
  background-color: ${gray};
  width: 75vw;
  height: 53vw;
  max-width: 750px;
  animation: ${pulseAnimation} 2s infinite ease-in-out;
`;

const Giraffe = styled.img`
  display: block;
  margin-left: auto;
  margin-right: 32px;
`;

const OurStory = () => (
  <>
    <Header page="our_story" />
    <TitleWrapper>
      <BoxWrapper>Our Story</BoxWrapper>
      <TextWrapper>is incomplete, without your support</TextWrapper>
    </TitleWrapper>
    <Wrapper>
      <Suspense fallback={<Fallback />}>
        <Collage />
      </Suspense>
    </Wrapper>
    <Letter />
    <ContactUs />
    <Giraffe src={giraffe} />
    <OurTeam />
    <Footer page="our_story" />
  </>
);

export default OurStory;
