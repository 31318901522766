import React from "react";
import styled from "styled-components";
import { ourStoryStyles, fonts } from "../../styles.jsx";
import signature from "../../images/ourStory/ourStorySignature.svg";
import ucsf from "../../images/ourStory/ourStoryUCSF.jpg";

const { mont } = fonts;
const { primary, darkBlue } = ourStoryStyles;

const LetterWrapper = styled.div`
  padding-top: 5vh;
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Statement = styled.div`
  width: 75%;
  font-family: ${mont};
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
`;

const Signature = styled.img`
  padding-top: 16px;
  margin-left: 20%;
  margin-right: auto;
  padding-bottom: 32px;
  @media (max-width: 500px) {
    width: 140px;
  }
`;

const BottomWrapper = styled.div`
  padding-top: 32px;
  padding-bottom: 32px;
  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const DearFriend = styled.p`
  color: ${primary};
  padding-bottom: 32px;
`;

const Communites = styled.div`
  height: 200px;
  width: 100%;
  font-family: ${mont};
  color: ${darkBlue};
  font-weight: 700;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const Ucsf = styled.img`
  max-width: 80vw;
`;

const Letter = () => (
  <LetterWrapper>
    <Statement>
      <DearFriend>Dear Friend,</DearFriend>
      <p>
        My name is Akshay Sharma. In January of 2016, I founded the Why
        Childhood Cancer Foundation. Having finished treatment just a year
        before, the stories of patients and their families were fresh in my
        mind.
      </p>
      <p>
        Cancer treatment is not isolated; an entire family endures it. Amongst
        the bald heads and IV poles lives the fragile composure of these
        families. Each looking to one another for courage, staying afloat like
        red ants forming a raft during a flood. They search for answers. What
        happened to your child? How long is the treatment? Do they get
        radiation? A bone marrow transplant? A surgery? Life becomes about
        bargaining with fate, trying to find the “better” cancer. In
        desperation, their question of Why did my child deserve this? Becomes
        Why did my child deserve this one?
      </p>
      <p>
        They don’t deserve it. I can say that firmly. But it doesn’t change the
        circumstances of approximately 17,000 children that are diagnosed with
        cancer each year. And it doesn’t change the question: why?
      </p>
      <p>
        The stories and conversations I had with patient families helped me
        realize the growing challenges that existed outside the hospital. By
        assisting these families with rent, bills, food, or transport, we strive
        to empower them to focus on their child’s health and healing.
      </p>
      <p>
        Through your continuous support and donations, we hope to be of some
        value to these families. I appreciate your interest in our cause and
        invite you to join us.
      </p>
      <p>Thank you,</p>
    </Statement>
    <BottomWrapper>
      <Signature src={signature} />
      <Communites>
        <p style={{ paddingBottom: "16px", textAlign: "center" }}>
          Communities That We Support
        </p>
        <Ucsf src={ucsf} />
      </Communites>
    </BottomWrapper>
  </LetterWrapper>
);

export default Letter;
