import React from "react";
import styled from "styled-components";
import { homeStyles, fonts } from "../styles.jsx";
import Opener from "./Home/Opener.jsx";
import SuperPowers from "./Home/SuperPowers.jsx";
import Mission from "./Home/Mission.jsx";
import Header from "./Common/Header.jsx";
import Footer from "./Common/Footer.jsx";
import ImpactNumbers from "./Home/ImpactNumbers.jsx";
import KidWithABeard from "./Home/KidWithABeard.jsx";

const { primary, white } = homeStyles;
const { pop } = fonts;

const HomeTopHalf = styled.div`
  background-color: ${primary};
  font-family: ${pop};
  color: ${white};
  margin-top: 100px;
`;

const HomeBottomHalf = styled.div``;

const Home = () => (
  <>
    <Header page="home" />
    <HomeTopHalf>
      <Opener />
    </HomeTopHalf>
    <HomeBottomHalf>
      <SuperPowers />
      <Mission />
      <ImpactNumbers />
      <KidWithABeard />
    </HomeBottomHalf>
    <Footer page="home" />
  </>
);

export default Home;
