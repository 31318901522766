import React from "react";
import styled from "styled-components";
import { homeStyles, fonts } from "../../styles.jsx";
import dog from "../../images/home/homePageDog.svg";

const { white, darkBlue } = homeStyles;
const { mont } = fonts;

const MissionWrapper = styled.div`
  background-color: ${white};
  display: flex;
  padding-left: 12%;
  padding-right: 12%;
  padding-bottom: 64px;
  align-items: center;
  justify-content: center;

  @media (max-width: 1250px) {
    flex-direction: column-reverse;
    width: 100%;
    height: 800px;
    padding: 0px;
  }
`;

const LeftSection = styled.div`
  display: flex;
  width: 600px;
  height: 256px;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 320px;
    padding: 0px;
  }
`;

const LeftTitle = styled.div`
  display: flex;
  align-self: flex-start;
  font-size: 32px;
  font-family: ${mont};
  color: ${darkBlue};
  font-weight: 900;
  padding-bottom: 16px;
`;

const LeftFirstText = styled.div`
  display: flex;
  align-self: flex-start;
  font-size: 20px;
  font-family: ${mont};
  color: ${darkBlue};
  line-height: 1.6;
  font-weight: 400;
`;

const LeftSecondText = styled.div`
  display: flex;
  align-self: flex-start;
  font-size: 20px;
  font-family: ${mont};
  color: ${darkBlue};
  line-height: 1.6;
  font-weight: 400;
`;

const RightSection = styled.div`
  padding-left: 128px;
  @media (max-width: 768px) {
    padding-left: 0px;
  }
`;

const RightImage = styled.img`
  width: 320px;
  height: 320px;
`;

const Mission = () => (
  <MissionWrapper>
    <LeftSection>
      <LeftTitle>Our Mission</LeftTitle>
      <LeftFirstText>
        Why? Childhood Cancer Foundation is a 501(c)(3) nonprofit organization
        that works to supplement the needs and costs associated with pediatric
        cancer.
      </LeftFirstText>
      <LeftSecondText>
        Our mission is to help children and families with the financial issues
        they may face on a day to day basis.
      </LeftSecondText>
    </LeftSection>
    <RightSection>
      <RightImage alt="Doggie" src={dog} />
    </RightSection>
  </MissionWrapper>
);

export default Mission;
