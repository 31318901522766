import React from "react";
import styled from "styled-components";
import { resourcesStyles, fonts } from "../../styles.jsx";
import title from "../../images/resources/resourcesBookTitle.svg";
import fund from "../../images/resources/resourcesFundraiser.svg";
import book from "../../images/resources/resourcesBookDrawing.png";
const { black, blue } = resourcesStyles;
const { mont } = fonts;

const BoxWrapper = styled.div`
  display: block;
  width: 85%;
  border-style: solid;
  border-color: ${blue};
  margin-left: auto;
  margin-right: auto;
  margin-top: 64px;
  margin-bottom: 64px;
`;

const DisplayWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const BoxLeft = styled.div`
  display: flex;
  width: 60%;
  flex-direction: column;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 64px;
  padding-bottom: 64px;
`;

const BoxRight = styled.div`
  display: flex;
  width: 40%;
  flex-direction: column;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 32px;
`;

const TitleImage = styled.img`
  display: flex;
  width: 100%;
`;

const FundImage = styled.img`
  display: flex;
  width: 50%;
  align-self: flex-end;
`;

const BookImage = styled.img`
  display: flex;
  width: 100%;
  padding-top: 64px;
  padding-bottom: 64px;
`;

const Text = styled.div`
  display: flex;
  text-align: center;
  font-size: 20px;
  font-family: ${mont};
  color: ${black};
  padding-left: 20%;
  padding-right: 20%;
  padding-top: 32px;
  padding-bottom: 32px;
`;

const ResourcesBook = () => (
  <BoxWrapper>
    <DisplayWrapper>
      <BoxLeft>
        <TitleImage alt="title of book" src={title} />
        <Text>
          Don’t just donate, have fun with it. With each donation, go on an
          adventure with Kid in this interactive coloring storybook!
        </Text>
      </BoxLeft>
      <BoxRight>
        <FundImage alt="fundraiser image" src={fund} />
        <BookImage alt="images of books to support" src={book} />
      </BoxRight>
    </DisplayWrapper>
  </BoxWrapper>
);

export default ResourcesBook;
