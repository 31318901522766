import React, { useRef } from "react";
import styled from "styled-components";
import {
  fonts,
  donateStyles,
  ourStoryStyles,
  resourcesStyles,
} from "../../styles.jsx";
import paypal from "../../images/donate/paypal-donate-button.png";
import { Card } from "../Common/ResusableComponents.jsx";

const { white } = resourcesStyles;

const { secondary } = ourStoryStyles;

const Wrapper = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(
    to top,
    ${white} 0%,
    ${white} 30%,
    ${secondary} 30%,
    ${secondary} 100%
  );
`;

const PaypalWrapper = styled(Card)`
  font-family: ${fonts.mont};
  display: flex;
  flex: 0.5;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75%;
  padding: 25px;
  border-radius: 16px;
  background-color: ${donateStyles.white};
  cursor: pointer;
`;

const PayPalImage = styled.img`
  @media (max-width: 780px) {
    width: 300px;
  }
  @media (max-width: 400px) {
    width: 200px;
  }
`;

const BlueText = styled.h2`
  color: ${donateStyles.blue};
  justify-content: center;
  font-size: 32px;
  text-align: center;
`;

const About = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 75%;
  text-align: center;
`;

const Paypal = () => {
  const formRef = useRef();

  const handleWrapperClick = (e) => {
    e.preventDefault();
    formRef.current.submit();
  };

  return (
    <Wrapper
      action="https://www.paypal.com/donate"
      method="post"
      target="_blank"
      ref={formRef}
    >
      <input type="hidden" name="hosted_button_id" value="YEYMRUZRTYEGS" />
      <PaypalWrapper onClick={handleWrapperClick}>
        <BlueText>Donate with PayPal Giving Fund</BlueText>
        <PayPalImage
          type="image"
          src={paypal}
          border="0"
          alt="Donate with PayPal button"
          width="350"
          height="auto"
        />
        <h2>About this charity</h2>
        <About>
          {"\n"}
          {`Why? Childhood Cancer Foundation is a 501(c)(3) nonprofit organization
          that works to supplement the needs and costs associated with pediatric
          cancer. Why's mission is to help children and families with the
          financial issues they may face on a day to day basis. We understand that
          when a child gets cancer, a family goes through treatment. All donations are tax-deductible and a receipt is provided from Paypal after completion of a donation.`}
          {"\n"}
        </About>
      </PaypalWrapper>
    </Wrapper>
  );
};

export default Paypal;
